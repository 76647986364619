.main_page{
  padding: 5px;
}

.step-content{
  margin-top: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  overflow: visible;
}
.form-content{
  margin-top: 10px;
}

body{
  font-family: Microsoft YaHei;
  font-size: .18667rem;
  color: #333;
}
body, html {
  min-height: 100%;
  //background-color: #eeeeee;
}
.am-image-picker-list .am-image-picker-upload-btn{
background: rgba(255,255,255,0);
}
.am-image-picker-list .am-image-picker-upload-btn:before, .am-image-picker-list .am-image-picker-upload-btn:after{
  width: 0;
  height: 0;
  background: rgba(255,255,255,0);
}

.idc1{
  background: url("../../../assets/images/idCard1.png") no-repeat;
  background-size: 100% 100%;
  width: 85%;
  height:83%;
  margin: 9px 8px 0;
  position: absolute;
}
.idc2{
  background: url("../../../assets/images/idCard2.png") no-repeat;
  background-size: 100% 100%;
  width: 85%;
  height:83%;
  margin: 9px 8px 0;
  position: absolute;
}
.idc3{
  background: url("../../../assets/images/licenseOn.png") no-repeat;
  background-size: 100% 100%;
  width: 85%;
  height:83%;
  margin: 9px 8px 0;
  position: absolute;
}
.idc4{
  background: url("../../../assets/images/licenseBa.png") no-repeat;
  background-size: 100% 100%;
  width: 85%;
  height:83%;
  margin: 9px 8px 0;
  position: absolute;
}
@primary-color: #1890FF;