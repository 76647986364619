
* {
  padding: 0;
  margin: 0;
}
.noSliding{
  overflow: hidden;
  height: 100%;
}
.back {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  .backImg{
    width: 80%;
    height: 50%;
    margin-left: 15%;
  }
}

.noBack {
  display: none;
}

.installation {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
  font-size: 1rem;
  width: 100%;
  min-height: 100%;
  background-color: #3483ff;

  .titleBar {
    height: 7rem;
  }

  .wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 800px;
    padding: 0 1rem .8rem;

    .wrapperList {
      background-color: #fff;
      border-radius: 8px;
      font-size: 15px;
      color: #212121;
      padding: 14px;
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .productList {
      border-radius: 8px;
      padding: 0 1rem;
      background-color: #fff;

      .item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        line-height: 1;
        font-size: 14px;
        color: #212121;
        padding: 1rem 0;
        position: relative;

        .item-tit {
          font-size: 20px;
          font-weight: 900;
        }

        .item-desc {
          text-align: left;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          font-size: 12px;
          color: #8c9096;
          margin-top: .3rem;
          line-height: 1;
        }

        .item-code {
          position: relative;
          overflow: hidden;
          margin: 0.7rem;
          border-radius: 14px;
          -webkit-box-shadow: 0 0 12px 1px #cfcfcf;
          box-shadow: 0 0 12px 1px #cfcfcf;
          padding: 7.5px;

          .alert-text {
            font-size: .7rem;
            color: red;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }

          .tag {
            position: absolute;
            right: 0;
            top: 0;
            right: -16px;
            top: 8px;
            height: 18px;
            width: 65px;
            color: #fff;
            text-align: center;
            font-size: 12px;
            line-height: 18px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: rgb(77, 149, 252);
          }

          .img {
            display: inline-block;
            height: 6rem;
            width: 6rem;
          }
        }

        .code-import {
          font-size: .2rem;
          margin-top: .1rem;
          color: #96999e;
        }

        .item-btn {
          display: inline-block;
          height: 50px;
          width: 50%;
          max-width: 220px;
          line-height: 50px;
          text-align: center;
          background-color: #327afa;
          color: #fff;
          font-size: 16px;
          border-radius: 50px;
          text-decoration: none;
          outline: none;
          margin-top: 20px;
        }
      }
    }
  }

  .footer {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 20px;
    text-align: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@primary-color: #1890FF;