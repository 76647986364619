
.login-header{
  padding: .9333rem .9333rem 0 .9333rem;
  margin-bottom: 1.2rem;
  margin-left: .18667rem;
  h3{
    font-size: .74667rem;
    font-weight: 700;
    //color: #333;
    color: #fff;
    margin-bottom: .2rem;
  }
  p{
    font-size: .4rem;
    //color: rgba(0,0,0,.64);
    color: #ffffff;
  }
}
.am-list-body{
  background:transparent;
  background: rgba(0,0,0,0.3);
  margin: 10px;
  .am-list-item .am-input-extra{
    color: white
  }
  .input-content{
    width: 100vw;
    //height: 280px;
    display:flex;
    flex-direction: column;
    border-radius: 10px;
    background:transparent;

    .am-list-body{
      background-color: transparent;
    }
    .input-box{
      width: 100%;
      height: 70px;
      display:flex;
      flex-direction: row;
      margin: 0 auto;
      margin-top: 30px;
      background:transparent;
      .am-list-item{
        background:transparent;
      }
      .input-box-input-item{
        flex: 0.5 0 180px;
        input{
          padding-left: 10px;
          background:transparent;
          color: #ffffff;
        }
      }
      .input-box-item{
        margin-left: 30px;
        background:transparent;
      }
    }
  }
}


//.input-radio .am-radio{
//  padding: 2.5px;
//  border: 1px solid #ccc;
//  border-radius: 50%;
//  margin-right: 5px;
//  margin-left: 10px;
//}
.input-footer{
  background:transparent;
  height:40px;
  margin-left: 10px;
  line-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #ffffff;
  font-size: 14px;
}

body{
  font-family: Microsoft YaHei;
  font-size: .18667rem;
  color: #333;
}
body, html {
  min-height: 100%;
  background:transparent;
  //background-color: #ffffff;
}
@primary-color: #1890FF;