.page_home{
  width:100%;
  height:100%;
  background:#F0F2F5;
  padding:20px;
  ::-webkit-scrollbar {
    width:9px;
    height:9px;
  }

  /*外层轨道。可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果*/

  ::-webkit-scrollbar-track {
    width: 6px;
    background-color:rgba(0,0,0,0);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }

  /*滚动条的设置*/

  ::-webkit-scrollbar-thumb {
    background-color:#cccccc;
    background-clip:padding-box;
    min-height:28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
  /*滚动条移上去的背景*/

  ::-webkit-scrollbar-thumb:hover {
    background-color:#cccccc;
  }
  .card_main{
    width: 100%;
    height: 100%;
    overflow: scroll;


}
}
@primary-color: #1890FF;