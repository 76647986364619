@height_top:40px;
@height_bottom:94px;
@height_btn:48px;
.home{

  .pos{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  // 加盟须知 标题
  .title{
    .pos();
    height: @height_top;
    top:0;
    width:100vw;
    font-weight: 400;
    line-height: @height_top;
    padding-left: 10px;
    font-size:16px;
    background: #fff;
  }
  .img{
    width:100vw;
    margin: @height_top 0 @height_bottom 0;
  }
  // 我要加盟 按钮
  .btn_box{
    border: none;
    width:100vw;
    height:@height_bottom;
    bottom: 0;
    .pos();
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn{
      height: @height_btn;
      background-color: #108ee9;
      width: 84%;
      font-size:20px;
      color: #ffffff;
      border-radius: 5px;
      text-align: center;
      line-height:@height_btn;
    }
  }
}
@primary-color: #1890FF;