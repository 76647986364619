html,body{
    height: 100%;
}
.btnlogin{
    animation-name: glowing-primary;
    border-color: #f29c1f;
    background: #f29c1f;
    border-style: solid;
    border-width: 1px;
    line-height: 38px;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.3), 0 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    height: 40px;
    padding: 0 40px;
    margin: 0;
    display: inline-block;
    appearance: none;
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    color:white;

}
.inputlogin{
    width: 75%;
    height: 40px;
    border: none;
    padding: 5px;
    margin-left: 10px;
    background: grey;
    color: white;
    font-size: 18px;
}
.inputlogin2{
    width: 50%;
    height: 40px;
    border: none;
    padding: 5px;
    margin-left: 10px;
    background: grey;
    color: white;
    font-size: 18px;

}
.PromoterHome2{
    background-image:url("../../../assets/Img/home_p.png");
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.PromoterHome{
    background-image:url("../../../assets/Img/home_p.png");
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.boxInput{
    margin-top: 30px;
    display: flex;
    background: grey;
    width: 85%;
    padding: 10px;
    border-radius: 25px;
    align-items: center;
}
.am-pagination-wrap{
    color: white !important;
}
::-webkit-input-placeholder { /* WebKit browsers */
    color: white;
    font-size: 16px;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: white;
    font-size: 16px;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: white;
    font-size: 16px;
}